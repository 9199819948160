import {factorOut,factorIn, mix, clamp, easing} from './math.js'
// import logoSvg from '/src/assets/animation-header-home.svg'

const {ScrollMagic} = window;

const scrollController = new ScrollMagic.Controller();

window.document.addEventListener('setTheme', (e) => {
    window.document.body.setAttribute('data-theme', e.detail );
})

function onWindowResize(callback) {
    // Function to check and return the orientation
    function checkOrientation() {
        return window.innerWidth > window.innerHeight;
    }

    var orientation = null;
    function checkOrientationChange() {

        var orientation = checkOrientation();
        const {innerWidth,innerHeight} = window;
        callback( {isLandscape:orientation,innerWidth,innerHeight} );

    }
    
    checkOrientationChange();

    // Event listener for window resize
    window.addEventListener('resize', checkOrientationChange );
}


// initLogo( '.logo.animated' )
// .then( ( elLogo ) => {

//     const animation = ( callback ) => {

//         var running = true;
//         var timeStarted = Date.now();
//         const cache = {};

//         const update = () => {

//             if( running ){
                
//                 try{
                    
//                     callback( (Date.now() - timeStarted) / 1000, {cache} );

//                 }catch( err ){
                        
//                 }
                
//                 requestAnimationFrame( update );
//             }

//         }

//         // update();
//         callback( 0, {cache} );

//         return {
//             stop : () => running = false,
//             update : ( progress ) => {
//                 callback( progress * 4, {cache} );
//             }
//         }
//     }

//     const animations = {
//         play : ( target ) => animation( ( time, {cache} ) => {
//             //
//             var ball = cache.ball = cache.ball || target.querySelector('#animation-play-ball');
//             // console.log( guide );
//             var progress = ( time / mix( 1.5, 1.7, factorIn( time, 3, 10 ) ) ) % 2;
//             if( progress > 1 ){
//                 progress = 2 - progress;
//             }

//             const range = 1650;
//             ball.style.transform = `translateX(${mix( 0.5 * range, -0.5 * range, progress )}px)`;
//         } ),
//         mobile : ( target ) => animation( ( time, {cache} ) => {

//             //
//             var icon = cache.icon = cache.icon || target.querySelector('#animation-mobile-icon');
//             var iconShape = cache.iconShape = cache.iconShape || target.querySelector('#animation-mobile-icon g');
//             var progress = ( time / 4 );
            
//             const angle = (0.15 + 0.55 * progress) * Math.PI;//0.5 * Math.PI * Math.sin( progress );
//             const rx = 2000;
//             const ry = 600;

//             icon.style.transform = [
//                 `translateX(${(-rx * Math.cos( angle )).toFixed(1)}px)`,
//                 `translateY(${(ry * Math.cos( 2 * angle )).toFixed(1)}px)`
//             ].join(' ');
            
//             iconShape.style.transform = [
//                 `rotate(${-(60 * Math.cos( angle )).toFixed(1)}deg)`
//             ].join(' ');

//             iconShape.style.transformOrigin = `3000px 1000px`;

//         } ),
//         sharing : ( target ) => animation( ( time, {cache} ) => {
//             //
//             var e1 = cache.e1 = cache.e1 || target.querySelector('#animation-sharing-E1');
//             var e2 = cache.e2 = cache.e2 || target.querySelector('#animation-sharing-E2');
//             var heart = cache.heart = cache.heart || target.querySelector('#animation-sharing-heart');

//             // console.log( guide );
//             var progressShift = factorIn( time, 2, 3 );
//             var progressOpacityE = factorIn( time, 1, 2 );
//             var progressOpacityHeart = factorIn( time, 3, 4 );
            
//             var shift = 200;
            
//             e1.style.transform = `translateX(${mix( 0, shift, progressShift ).toFixed(1)}px)`;
//             e2.style.transform = `translateX(${mix( 0, -shift, progressShift ).toFixed(1)}px)`;
//             e2.style.opacity = mix( 1, 0.5, progressOpacityE );
//             heart.style.opacity = mix( 0, 1, progressOpacityHeart );
            
//         } ),
//     }
    
//     var currentAnimation = null;

//     initHeader( scrollController, '.header', {
//         onWordChange : ( word ) => {

//             if( currentAnimation ){
//                 currentAnimation.stop();
//             }

//             word = word ? word.toLowerCase() : null;
//             var layer = word ? `animation-${word}` : 'background';

//             elLogo.querySelectorAll('svg>g').forEach( elWord => {
//                 const id = elWord.getAttribute('id');
//                 const enabled = id == layer;
//                 elWord.setAttribute('data-enabled', enabled ? 'true' : 'false');
                
//                 if( enabled ){
//                     const animation = animations[word];
//                     console.log('showAnimation', animation );
//                     if( animation ){
//                         currentAnimation = animation( elWord );
//                     }
//                 }
//             } );

//         },
//         onWordProgress : ( progress ) => {

//             if( currentAnimation ){
//                 currentAnimation.update( progress );
//             }
//         }
//     } );

// });

// initBody( scrollController, '.body');
// initAllServices( scrollController, '.service');
initFooter( scrollController, '.footer');
initContact( scrollController, '.contact');

function initHeader( scrollController, selector, {
    onWordChange = ( word ) => {

    },
    onWordProgress = ( index ) => {

    },
    onProgress = ( index ) => {

    }
} ) {
    const elHeader = document.querySelector( selector );
    const elHeaderSlide = document.querySelector( '.header-slide' );
    const elHeaderTitle = elHeader.querySelector( 'h2' );
    const elTypeWriter = elHeader.querySelector("[data-id='typewriter'] [data-type='content']");

    const words = ['PLAY','MOBILE','SHARING'];
    var currentIndex = -1;

    var timer = null;

    const progressBar = initProgressBar( elHeader, words.length );

    var previousWord = null;
    const showWord = ( word ) => {

        word = word || previousWord;

        if( word == previousWord ){
            return null;
        }

        if( timer ){
            clearInterval( timer );
        }

        elTypeWriter.innerText = "";

        if( word ){
            previousWord = word;
            var index = 0;
            onWordChange( word );
            timer = setInterval( () => {

                if( ++index <= word.length ){
                    elTypeWriter.innerText = word.slice( 0, index );
                }else{
                    clearInterval( timer );
                    timer = null;
                }

            }, 100 );
        }else{
            elTypeWriter.innerHTML = "&nbsp;";
        }

        
    }

    const progressWord = ( word, progress, {silent = false} = {}  ) => {   
        
        if( word != previousWord && !silent ){
            previousWord = word;
            onWordChange( word );
        }
        
        if( word ){
            var index = Math.floor( progress * (word.length + 2) );
            elTypeWriter.innerText = word.slice( 0, index );
            // if( index < word.length ){
            // }
        }else{
            elTypeWriter.innerHTML = "&nbsp;";
        }
    }

    const onHeaderProgress = (p) => {

        const {progress:iprogress,enterProgress,exitProgress,state} = p;

        const progress = iprogress;

        if( progress > 0 && progress < 1 ){
            window.document.dispatchEvent( new CustomEvent('setTheme', {detail:'light'}) );
        }

        elHeader.style.display = exitProgress == 1 ? 'none' : '';

        progressBar.update( progress );

        const overlap = 0.1;

        const pReveal = factorIn( progress, 0, overlap ) * factorOut( progress, 1 - overlap, 1 );

        elHeaderTitle.style.opacity = factorOut( exitProgress, 0.15, 0.4 );
        
        // elHeader.setAttribute('data-theme', exitProgress > 0.45 ? 'dark' : 'light' );
        // elHeader.style.transform = `translateY(${mix( 0, -45, factorIn( exitProgress, 0.6, 1 ) )}vh)`;

        if( pReveal < 1 ){
            progressWord( null );

            const progressOut = factorOut( progress, 1 - overlap, 1 )

            if( progressOut < 1 ){
                
                progressWord( 'HUMANS', factorIn( progress, 1 - overlap, 1 ), {silent:true} );
                
            }


        }else{
            const p = factorIn( progress, overlap, 1 - overlap );

            const wordsProgress = Math.min( p * (words.length + 0), words.length - 0.0001 );
            const index = Math.floor( wordsProgress );
            
            
            if( index != currentIndex ){
                currentIndex = index;
            }
    
            // elHeader.setAttribute('data-theme', index % 2 == 0 ? 'dark' : 'light' );
            elHeader.style.backgroundPosition = [
                `50%`,
                `calc( ${mix( -70, 250, factorIn( progress, 0, 1 ) ).toFixed(1)}% )`
            ].join( ' ' );
            
            progressWord( words[ currentIndex ], wordsProgress - index );
            onProgress( p );
            onWordProgress( wordsProgress - index );
        }
        //- console.log( progress, index );
    }

    onScrollProgress( 
        new ScrollMagic.Scene({
            triggerElement: elHeaderSlide,
            triggerHook: 0,
            duration: "200%",
            // pushFollowers: false
        })
        .setPin( elHeaderSlide )
        // .addIndicators()
        .addTo( scrollController ),
        onHeaderProgress,
        {debug:false}
    );
    // .on('progress', onSceneProgress );

    // onSceneProgress( {progress:0} );
}

function initBody( scrollController, selector ){

    const elBody = document.querySelector( selector );
    const elBodyLogo = elBody.querySelector( '.logo' );
    const elBodyTitle = elBody.querySelector( 'h2' );
    
    const onBodyProgress = (p) => {

        const {progress,enterProgress, exitProgress, state, scrollDirection} = p;

        const prog = 0.5 * (enterProgress + exitProgress);

        if( exitProgress > 0 && exitProgress < 1 ){
            window.document.dispatchEvent( new CustomEvent('setTheme', {detail:'dark'}) );
        }

        elBody.style.backgroundPosition = [
            `50%`,
            `calc( 50% + ${mix( -50, 50, factorIn( prog, 0, 1 ) ).toFixed(1)}% )`
        ].join(' ');

        elBodyLogo.style.transform = `translateY(${mix( -50, 0, factorIn( enterProgress, 0.5, 1 ) )}vh)`;
        elBodyLogo.style.opacity = elBodyTitle.style.opacity = mix( 1, 0, factorIn( exitProgress, 0.25, 0.5 ) );
    }

    onScrollProgress( 
        new ScrollMagic.Scene({
            triggerHook: "onLeave",
            triggerElement: elBody,
            duration: "1%",
            pushFollowers: false
        })
        .setPin( elBody )
        .addTo( scrollController ),
        onBodyProgress
    )
}

function initAllServices( scrollController, selector ){

    const elServices = document.querySelectorAll( selector );

    elServices.forEach( (service, index) => {

        // const progressBar = initProgressBar( service, 1 );
        const elServiceIntro = service.querySelector('.intro');
        const elServiceTitle = service.querySelector('.title');
        const elServiceTitleSummary = elServiceTitle.querySelector('.summary');
        const elServiceSlidingImage = elServiceIntro.querySelector('.sliding-image');
        const elServiceGlare = service.querySelector('.glare');
        // const elImage = elImageFrame.querySelector('.image');

        elServiceTitle.style.position = 'absolute';

        const onServiceProgress = (p) => {

            const {progress,enterProgress,exitProgress} = p;

            const prog = (1 / 3) * (enterProgress + 1 * progress + exitProgress);

            if( prog > 0 && prog < 1 ){
                window.document.dispatchEvent( new CustomEvent('setTheme', {detail:prog > 0.5 ? 'dark' : 'light' }) );
            }
            
            elServiceIntro.style.backgroundPosition = [
                `50%`,
                `calc( 100px + ${mix( 50, -50, factorIn( prog, 0.25, 0.75 ) ).toFixed(1)}px )`
            ].join(' ');

            elServiceGlare.style.top = `${mix( -40, 80, factorIn( prog, 0, 1 ) )}%`;

            // elServiceTitle.style.transform = `translateY(${mix( 20, 0, factorIn( progress, 0, 0.5 ) )}vh)`;
            
            const progressTitle = factorIn( progress, 0.5, 0.9 );
            elServiceTitle.style.top = `${mix( 100, 0, progressTitle )}%`;
            if( elServiceTitleSummary ){
                elServiceTitleSummary.style.top = `${mix( -100, 0, progressTitle )}%`;
            }

            elServiceTitle.style.backgroundPosition = [
                `0%`,
                `calc( ${mix( -100, 0, factorIn( progress, 0.5, 1 ) ).toFixed(1)}vh )`
            ].join(' ');

            
            var [dx,dy] = elServiceSlidingImage.getAttribute('data-direction').split(' ');

            const calcShift = ( d ) => {
                var shift = 50 * Number( d );
                const prog = 0.5 * (enterProgress + progress);
                return `calc( 50% + ${mix( -shift, shift, factorIn( prog, 0, 1 ) ).toFixed(1)}% )`
            }
            
            if( elServiceSlidingImage ){
                elServiceSlidingImage.style.backgroundPosition = [
                    calcShift( dx ),
                    calcShift( dy ),
                ].join(' ');
            }
            // if( index == 0 ){
            //     console.log( elServiceIntro.style.backgroundPosition )
            // }

        }

        onScrollProgress(
            new ScrollMagic.Scene({
                triggerHook: "onLeave",
                triggerElement: service,
                duration: "100%",
                pushFollowers: false
            })
            .setPin( service )
            .addTo( scrollController )
            ,
            onServiceProgress
        )

        const resize = () => {

            elServiceSlidingImage.style.height = '0%';
            elServiceSlidingImage.style.border = 'none';
            elServiceSlidingImage.style.borderTop = 'none';
            elServiceSlidingImage.style.borderBottom = 'none';

            const {width,height} = elServiceSlidingImage.getBoundingClientRect();
            const {naturalWidth,naturalHeight} = elServiceSlidingImage;
            const naturalRatio = naturalWidth / naturalHeight;
            const ratio = width / height;

            if( ratio < naturalRatio ){
                const h = width / naturalRatio;
    
                const padding = 0.5 * (height - h);
                elServiceSlidingImage.style.borderTop = `${padding}px solid white`;
                elServiceSlidingImage.style.borderBottom = `${padding}px solid white`;
            }
        }

        elServiceSlidingImage.onload = resize;

        onWindowResize( resize )
    } )
}

function initLogo( selector, svg ){

    return new Promise( ( resolve, reject ) => {
        const elLogo = document.querySelector( selector );
 
        resolve( elLogo );
    } );

}

function initProgressBar( container, count ){

    const elProgress = window.document.createElement('div');
    elProgress.className = 'progress';
    const elProgressBar = window.document.createElement('div');
    elProgressBar.className = 'bar';

    container.appendChild( elProgress );
    elProgress.appendChild( elProgressBar );

    return {
        update : ( progress ) => {
            elProgress.style.opacity = progress > 0 && progress < 1 ? 1 : 0;
            elProgressBar.style.height = `${(progress * 100).toFixed(1)}%`;
        }
    }

}

function onScrollProgress( scrollController, update, {debug = false} = {} ){

    var prevInnerProgress = -1;
    var prevEnterProgress = -1;
    var prevExitProgress = -1;
    scrollController.on('update', ( event ) => {

        const {innerHeight} = window;
        const {startPos, endPos, scrollPos} = event;
        const enterProgress = factorIn( scrollPos, startPos - innerHeight, startPos );
        const innerProgress = factorIn( scrollPos, startPos, endPos );
        const exitProgress = factorIn( scrollPos, endPos, endPos + innerHeight );

        if( debug ){
            console.log( event, {innerProgress, enterProgress, exitProgress,innerHeight});
        }

        if( 
            prevInnerProgress != innerProgress || 
            prevEnterProgress != enterProgress || 
            prevExitProgress != exitProgress 
        ){
            prevInnerProgress = innerProgress;
            prevEnterProgress = enterProgress;
            prevExitProgress = exitProgress;
            update( {progress:innerProgress, enterProgress, exitProgress} );
        }
    });
}

function initContact( scrollController, selector ){


    const elContact = document.querySelector( selector );
    const elContactInput = elContact ? elContact.querySelector( 'input' ) : null;
    const elContactButton = elContact ? elContact.querySelector( 'button' ) : null;

    function sendData(email) {

        if( email ){

            email = email.toLowerCase();

            return fetch('https://p0d62z40n9.execute-api.eu-west-1.amazonaws.com/dev/newsletter/subscribe', {
              method: 'POST',
              body: JSON.stringify({ email: email }),
              headers: {
                'Content-Type': 'application/json'
              }
            })
            .then(response => response.text())
            .then(data => console.log(data))
            .catch(error => console.error('Error:', error));
        }
        
        return Promise.resolve();
      }

    if( elContactButton && elContactInput ){

        function onEmailUpdate(){
            const value = elContactInput.value;
            const isValid = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test( value );

            if( isValid ){
                elContactButton.removeAttribute("disabled");
            }else{
                elContactButton.setAttribute("disabled", "disabled");
            }

        }

        elContactInput.addEventListener( "input", () => {
            onEmailUpdate();
        });
        
        elContactButton.addEventListener( "mousedown", () => {
            console.log('clicked');
            sendData( elContactInput.value )
            .then( () => {
                elContactInput.value = '';
                onEmailUpdate();
            });
        });

        onEmailUpdate();

    }
    
}

function initFooter( scrollController, selector ){


    const elFooter = document.querySelector( selector );
    const elBase = document.querySelector( '.base' );

   
    const onProgress = (p) => {

        const {progress,state,scrollDirection} = p;

        if( state == 'DURING' ){
            window.document.dispatchEvent( new CustomEvent('setTheme', {detail:'light'}) );
        }
        
        console.log( progress );
        // elBase.style.display = `${state == 'DURING' ? 'display' : 'none'}`;

    }

    new ScrollMagic.Scene({
        triggerHook: "onLeave",
        triggerElement: elFooter,
        duration: "100%",
        pushFollowers: false
    })
    // .setPin( elFooter )
    .addTo( scrollController )
    .on('progress', onProgress );

    onProgress( {progress:0} )
    
}
